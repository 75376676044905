<template>
  <main class="bg-light background-image">
    <div
      v-if="ready"
      id="login" class="mx-auto d-flex justify-content-center align-content-center align-items-center flex-column h-100">
      <div class="w-100 border rounded p-4 mt-4 bg-white shadow-sm">
        <div>
          <h2>SAMPICK</h2>
        </div>
        <hr/>
        <b-form v-on:submit.prevent="login">
          <div>
            <b-form-input
                v-model="userId"
                placeholder="user id"
                autofocus
            />
          </div>
          <hr/>
          <div>
            <b-form-input
                type="password"
                v-model="userPwd"
                placeholder="user pwd"
            />
          </div>
          <hr/>
          <div>
            <b-button
                type="submit"
                squared
                block
                :disabled="pending"
            >
              <font-awesome-icon
                  icon="sign-in-alt"
              />
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "login",
  data: () => ({
    userId: null,
    userPwd: null,
    autoLogin: false,
    pending: false,
    url: null,
    ready: false
  }),
  created () {
    const { query } = this.$route
    if (query) {
      const { url } = query
      if (url) this.url = url
    }

    if (this.token) {
      this.verify()
    } else {
      this.ready = true
    }
  },
  methods: {
    async login () {
      try {
        const data = {
          userId: this.userId,
          userPwd: this.userPwd,
          autoLogin: this.autoLogin,
          url: this.url
        }
        this.pending = true
        const {result, error} = await this.$store.dispatch('signIn', data)
        if (error) {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
        this.pending = false
      } catch (e) {
        console.log(e)
      }
    },
    async verify () {
      this.pending = true
      try {
        const { result } = await this.$store.dispatch('verify')
        console.log(result)
        if (result) await this.$router.replace({path: this.url ? decodeURIComponent(this.url) : '/ad/list'})
      } catch (e) {
      }
      this.pending = false
      this.ready = true
    }
  },
  computed: {
    token () {
      const { token } = this.$store.getters
      return token
    }
  }
}
</script>

<style scoped lang="scss">
  main{
    margin: 0;
    padding: 0;
    height: 100vh;
  }
  div#login {
    max-width: 400px;
  }
  .background-image {
    //background-image: url("~@/assets/lighthouse-5622924_1920.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
</style>
